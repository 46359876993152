<template lang="pug">
.main-wrapper.procedimentos-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-3
					Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-9.ta-right
					h1.text-header.text-secondary MedClub / <b>Mapa de Ocupação</b>
		
		Panel.my-2(header='Filtros' :toggleable='true' mode="indeterminate")
			form.p-grid.p-fluid(@submit.prevent='applyFilters()')
				.p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.filters.cd_estabelecimentos.$error }")
					label.form-label Estabelecimento:
					ProgressBar(v-if="waitingEstabelecimento" mode="indeterminate")
					.p-inputgroup(v-else)
						CustomDropdown(
							:options='options.estabelecimentos'
							@change="getEspecialistas();getSetores();getSalas();"
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='$v.filters.cd_estabelecimentos.$model'
						)

				.p-col-12.p-md-3
					label.form-label Setor:
					ProgressBar(v-if="waitingSetores" mode="indeterminate")
					.p-inputgroup(v-else)
						CustomDropdown(
							:options='options.cd_setores'
							@change="getSalas();"
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='filters.cd_setores'
						)

				.p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.filters.cd_salas.$error }")
					label.form-label Sala:
					ProgressBar(v-if="waitingSalas" mode="indeterminate")
					.p-inputgroup(v-else)
						CustomDropdown(
							:options='options.cd_salas'
							placeholder='TODAS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='$v.filters.cd_salas.$model'
						)

				.p-col-12.p-md-3
					label.form-label Especialista:
					ProgressBar(v-if="waitingEspecialistas" mode="indeterminate")
					.p-inputgroup(v-else)
						CustomDropdown(
							:options='options.cd_especialistas'
							:disabled='!filters.cd_estabelecimentos'
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='filters.cd_especialista'
						)

				.p-col-12.p-md-1(v-if="tipo_data=='dia'")
					label.form-label Data (Dia):
					.p-inputgroup
						Calendar( v-model='filters.dt_ocupacao' dateFormat="dd/mm/yy" 
							:manualInput='false')
						Button(icon='jam jam-rubber' @click='clearFilters();')
				.p-col-12.p-md-2(v-if="tipo_data=='dia'")
					label.form-label Ver todos os horários vagos:
					SelectButton(
						v-model="dias_vazios"
						:options="options.dias_vazios"
						optionLabel="text"
						optionValue="value"
						v-tooltip.top="`Não: Ver somente os horários vazios das Salas que possuem algum horário preenchido.\n\nSim: Ver até mesmo salas com todos os horários vagos.`"
						)
				.p-col-12.p-md-1(v-else-if="tipo_data=='mes'" :class="{ 'form-group--error': submitted && $v.filters.mes_ocupacao.$error }")
					label.form-label Mês:
					.p-inputgroup
						Calendar(v-model="filters.mes_ocupacao" view="month" dateFormat="mm/yy" :yearNavigator="true" yearRange="2000:2030" appendTo="body")
						Button(icon='jam jam-rubber' @click='clearFilters();')
				
				.p-col-12.p-md-1
					label.form-label Turno:
					.p-inputgroup
						Dropdown(
							:options='optionTurno'
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='valueTurno'
						)

				.p-col-12.p-md-2
					label.form-label Tipo de exibição:
					SelectButton(v-model="tipo_data" :options="options.tipo_data" optionLabel="text" optionValue="value" )

				.p-col-12.p-md-3
					label.form-label Horários:
					SelectButton(
						v-if="!filters.cd_setores && !filters.cd_salas && tipo_data == 'mes'"
						v-model="tipo_horarios"
						:options="options.tipo_horario_ocupado"
						optionLabel="text"
						optionValue="value"
						v-tooltip.top="`Selecione Setor e Sala para ver horários vagos.`"
					)
					SelectButton(
						v-else
						v-model="tipo_horarios"
						:options="options.tipo_horarios"
						optionLabel="text"
						optionValue="value"
					)

				.p-col-12.p-md-3.p-grid
					.p-col-6
						label.form-label Aplicar Filtros
						ProgressBar(v-if='waiting' mode="indeterminate")
						.p-inputgroup(v-else)
							Button(style="width: 100%" icon='jam jam-search' type="submit")
					.p-col-6
						label.form-label Limpar Filtros
						ProgressBar(v-if='waiting' mode="indeterminate")
						.p-inputgroup(v-else)
							Button.p-button-warning(style="width: 100%" icon='jam jam-rubber' @click='clearFilters();')
		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			.p-grid.p-fluid
				.p-col-12.p-md-10(style="height: 0px")
					br
				.p-col-12.p-md-2
					.p-inputgroup(style="position: absolute; margin-top: 20px")
						Button.p-button-primary(label="Imprimir o Mapa" icon='jam jam-printer' @click='gerarPdf()')
			.panel-print
				Panel.datatable
					template(#header)
						div.p-grid.p-fluid
							.p-col-12.p-md-6.ta-left
								h1.text-header.text-secondary(v-if="tipo_data=='mes' && filters.mes_ocupacao" style="font-size: large") Mapa de Ocupações: <b>{{ formatarData(filters.mes_ocupacao, 0) }}</b>
								h1.text-header.text-secondary(v-else style="font-size: large") Mapa de Ocupações: <b>{{ filters.dt_ocupacao_f }}</b>
						div.p-grid.p-fluid
							.p-col-12.p-md-3
								label.form-label Estab.: {{ options.estabelecimentos.find(item => item.value == filters.cd_estabelecimentos)?.text }}
							.p-col-12.p-md-3
								label.form-label Setor: {{ options.cd_setores.find(item => item.value == filters.cd_setores)?.text }}
							.p-col-12.p-md-3
								label.form-label Sala: {{ options.cd_salas.find(item => item.value == filters.cd_salas)?.text }}
							.p-col-12.p-md-3
								label.form-label Especialista: {{ options.cd_especialistas.find(item => item.value == filters.cd_especialista)?.text }}

					div(v-if="tipo_data=='dia'")
						DataTable( :value="list" dataKey="id" @sort='onSort' :sortField="order.field" :sortOrder="order.sortOrder" removableSort)
							Column(field="sala" header="Sala" key="id" headerStyle='width: 13%; text-align: center')
								template(#body='props')
									b {{ props.data.sala }} <br/>
									em(style='font-size: 15px;') {{ props.data.setor }}
							Column(v-for="(hora, index) of listaDinamica" :field="hora.value" :class="{s2: true}" :header="hora.value" bodyStyle='padding: 0; width:100% !important; heigth:100% !important'  headerStyle='text-align: center' :key="hora.field" )
								template(#body='props')
									div(v-if="tipo_horarios == 0")
										div.cell(
											v-if="props.data[hora.value] && props.data[hora.value].id_especialista"
											v-tooltip.top="`${ props.data[hora.value].nm_especialista }. \n\n Clique para alterar a Agenda.`"
											:style="{'background-color': props.data[hora.value].cor,'padding':'16px 0','text-align':'center','width':'100% !important','min-height':'9vh'}"
											@click="visualizar(props.data[hora.value].id_agenda)"
										)
											p <br>
									div(v-if="tipo_horarios == 1")
										div.cell-vago(
											v-if="props.data[hora.value] && !props.data[hora.value].id_especialista"
											v-tooltip.top="`${ props.data[hora.value].nm_especialista }.`"
										)
											p <br>
									div(v-if="tipo_horarios == 2")
										div.cell(
											v-if="props.data[hora.value] && props.data[hora.value].id_especialista"
											v-tooltip.top="`${ props.data[hora.value].nm_especialista }. \n\n Clique para alterar a Agenda.`"
											:style="{'background-color': props.data[hora.value].cor,'padding':'16px 0','text-align':'center','width':'100% !important','min-height':'9vh'}"
											@click="visualizar(props.data[hora.value].id_agenda)"
										)
											p <br>
										div.cell-vago(
											v-else-if="props.data[hora.value] && !props.data[hora.value].id_especialista"
											v-tooltip.top="`${ props.data[hora.value].nm_especialista }.`"
										)
											p <br>
					div(v-else-if="tipo_data=='mes'")
						DataTable(v-if="list[0]['0']" :value="list" dataKey="id")
							Column(v-for="(dia_semana, index) of columns.dias_semana" :header="dia_semana.value" :key="dia_semana.field")
								template(#body='props')
									.ta-center
										div(v-if="props.data[index].mapa.length")
											div(v-for="(dia, index) of props.data[index].mapa")
													div(v-for="(hora, index) of dia.ocupacoes")
														div(v-if="valueTurno != 0")
															div(v-if="hora.turno == valueTurno")
																// horarios ocupados
																div(v-if="tipo_horarios == 0")
																	// !!! esses estilos enormes repetidos nas div.mes-cell é pra poder sair o estilo no pdf !!!
																	div.mes-cell(
																			v-if="hora.id_especialista"
																			:style="{'background-color': hora.cor,'padding':'5px','text-align':'center','width':'100% !important','height':'fit-content','cursor':'pointer','color':'white','margin':'1px','border-radius':'15px'}"
																			@click="visualizar(hora.id_agenda)"
																		)
																			p {{ hora.hora }} {{ hora.nm_especialista }}
																			p(:style="{'border': '1px solid white','border-radius': '5px'}").identificadorSala <b>Setor:</b> {{ hora.nm_setor }};<br><b>Sala:</b> {{ hora.nm_sala }};
																// horarios vagos
																div(v-else-if="tipo_horarios == 1")
																	div.mes-cell(
																			v-if="!hora.id_especialista"
																			:style="{'background-color': hora.cor,'padding':'5px','text-align':'center','width':'100% !important','height':'fit-content','cursor':'pointer','color':'white','margin':'1px','border-radius':'15px'}"
																		)
																			p {{ hora.hora }} {{ hora.nm_especialista }}
																			p(:style="{'border': '1px solid white','border-radius': '5px'}").identificadorSala <b>Setor:</b> {{ hora.nm_setor }};<br><b>Sala:</b> {{ hora.nm_sala }};
																// horarios ambos
																div(v-else-if="tipo_horarios == 2")
																	div.mes-cell(
																			:style="{'background-color': hora.cor,'padding':'5px','text-align':'center','width':'100% !important','height':'fit-content','cursor':'pointer','color':'white','margin':'1px','border-radius':'15px'}"
																			@click="visualizar(hora.id_agenda)"
																		)
																			p {{ hora.hora }} {{ hora.nm_especialista }}
																			p(:style="{'border': '1px solid white','border-radius': '5px'}").identificadorSala <b>Setor:</b> {{ hora.nm_setor }};<br><b>Sala:</b> {{ hora.nm_sala }};
															div(v-else-if="hora.turno != valueTurno && hora.turno == 0")
																div(v-if="tipo_horarios == 0 || tipo_horarios == 2")
																	div.mes-cell(
																			v-if="hora.id_especialista"
																			:style="{'background-color': hora.cor,'padding':'5px','text-align':'center','width':'100% !important','height':'fit-content','cursor':'pointer','color':'white','margin':'1px','border-radius':'15px'}" @click="visualizar(hora.id_agenda)"
																		)
																			p {{ hora.hora }} {{ hora.nm_especialista }}
																			p(:style="{'border': '1px solid white','border-radius': '5px'}").identificadorSala <b>Setor:</b> {{ hora.nm_setor }};<br><b>Sala:</b> {{ hora.nm_sala }};
														div(v-else)
															div(v-if="tipo_horarios == 0")
																div.mes-cell(v-if="hora.id_especialista" :style="{'background-color': hora.cor,'padding':'5px','text-align':'center','width':'100% !important','height':'fit-content','cursor':'pointer','color':'white','margin':'1px','border-radius':'15px'}" @click="visualizar(hora.id_agenda)")
																	p {{ hora.hora }} {{ hora.nm_especialista }}
																	p(:style="{'border': '1px solid white','border-radius': '5px'}").identificadorSala <b>Setor:</b> {{ hora.nm_setor }};<br><b>Sala:</b> {{ hora.nm_sala }};
															div(v-else-if="tipo_horarios == 1")
																div.mes-cell(v-if="!hora.id_especialista" :style="{'background-color': hora.cor,'padding':'5px','text-align':'center','width':'100% !important','height':'fit-content','cursor':'pointer','color':'white','margin':'1px','border-radius':'15px'}")
																	p {{ hora.hora }} {{ hora.nm_especialista }}
																	p(:style="{'border': '1px solid white','border-radius': '5px'}").identificadorSala <b>Setor:</b> {{ hora.nm_setor }};<br><b>Sala:</b> {{ hora.nm_sala }};
															div(v-else-if="tipo_horarios == 2")
																div.mes-cell(:style="{'background-color': hora.cor,'padding':'5px','text-align':'center','width':'100% !important','height':'fit-content','cursor':'pointer','color':'white','margin':'1px','border-radius':'15px'}" @click="visualizar(hora.id_agenda)")
																	p {{ hora.hora }} {{ hora.nm_especialista }}
																	p(:style="{'border': '1px solid white','border-radius': '5px'}").identificadorSala <b>Setor:</b> {{ hora.nm_setor }};<br><i><b>Sala:</b> {{ hora.nm_sala }}</i>;
										div(v-else)
											p(:style="{'background-color': 'rgb(255,255,255)','padding': '5px','width': '100%','min-height': '3vh'}") Dia vago.
									.data-container
										b {{ formatarData(props.data[index].date, 1) }}
						div(v-else)
							p.ta-center.text-secondary(style='margin-top: 40px;') Nada para exibir, tente aplicar filtros.
</template>

<style lang="scss">
	.procedimentos-listar {
		.identificadorSala {
			border: 1px solid white;
			border-radius: 5px;
		}
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				width: 100% !important;
				min-height: 9vh;
				cursor: pointer;
				&.s1 { background-color: #EAE9E9; }
				&.s2 { background-color: #EAE9E9; }
			}
			.cell-vago {
				padding: 16px 0;
				text-align: center;
				width: 100% !important;
				min-height: 9vh;
				cursor: pointer;
				background-color: rgb(150,150,150);
			}
		}
		.data-container {
			position: relative;
			height: 30px;
			bottom: 0px;
			text-align: left;
		}
		.mes-cell {
				padding: 5px;
				text-align: center;
				width: 100% !important;
				height: fit-content;
				cursor: pointer;
				color: white;
				margin: 1px;
				border-radius: 15px;
			}
		.status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			background-color: #94c860;
			border: 1px solid #84b553;
			transition: 250ms;

			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
			}
			.jam {
				color: #fff;
			}
		}
		.textarea-informacoes,
		.textarea-preparo {
			height: 200px !important;
			overflow-y: auto !important;
			resize: none;
		}
		.textarea-observacoes {
			resize: none;
			height: 10rem;
			width: 100%;
			padding: 1%;
		}
		.ta-dialog {
			width: 35rem;
			margin: 10%;
			@media screen and (max-width:600px){
				width: inherit;
			}
			.p-multiselect, .p-dropdown {
				display: inline-grid;
			}
		}
		.aux2 {
			font-size: 13px;
			background-color: #FFFFE0;
			font-weight: 700;
			padding: 2px 6px;
			border-radius: 3px;
			margin-top: 4px;
		}
		.link-limpar {
			text-decoration: underline;
			font-size: 12px;
			cursor: pointer;
			font-weight: 700;
			margin-right: 10px;
			display: inline-block;
			margin-bottom: 6px;
		}
		.text-soma {
			margin-bottom: 4px;
			margin-right: 10px;
			display: inline-block;
			background-color: #faf3dd;
			box-shadow: 0 1px 2px #ddd;
			padding: 6px 10px;
		}
		.label-mostrar-selecionados {
			vertical-align: super;
			font-size: 12px;
			font-weight: 550;
		}
		.waiting-print-pdf {
			width: 25px;
			height: auto;
			margin-inline: 22px;
		}
		.increse-decrease-input {
			.p-inputtext {
				width: 40px;
				border-right: none;
			}
			.p-button {
				color: #ffffff;
				background: #64748B;
				border: 1px solid #64748B;
				&:hover {
					background: #475569;
					border-color: #475569;
				}
				&:disabled {
					&:hover {
						background: #64748B;
						border: 1px solid #64748B;
					}
				}
				&:focus {
					box-shadow: none;
				}
			}
		}
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Checkbox from 'primevue/checkbox'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dialog from 'primevue/dialog'
	import Dropdown from 'primevue/dropdown'
	import InputNumber from 'primevue/inputnumber'
	import InputText from 'primevue/inputtext'
	import InputSwitch from 'primevue/inputswitch'
	import Textarea from 'primevue/textarea'
	import ProgressSpinner from 'primevue/progressspinner'
	import MultiSelect from 'primevue/multiselect'
	import Calendar from 'primevue/calendar'
	import SelectButton from 'primevue/selectbutton';
	import moment from 'moment'
	import { Estabelecimento, MapaOcupacao } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	// import ImprimirPrecosPdf from "@/components/PrecoProcedimentos/ImprimirPrecosPdf"
	import ProcedimentoDropdown from "@/components/Procedimentos/ProcedimentoDropdown";
	import ConfirmDialog from "@/components/CustomComponents/ConfirmDialog";
	import CustomDropdown from "@/components/CustomComponents/CustomDropdown"

	// import Vue from "vue";
	import axios from "axios";
	// import { fakeListLoading } from "@/utils";
	import { requiredIf } from 'vuelidate/lib/validators'
	import html2pdf from "html2pdf.js";
	import { logoMedclubBase64 } from './../../utils';

	export default {
		created () {
			this.listaDinamica = this.columns.manha.concat(this.columns.tarde);
			this.getEstabelecimentos();
			this.getSetores();
			this.getSalas();
			this.applyFilters();
		},
		components: {
			ProcedimentoDropdown, Calendar,
			ProgressBar, DataView, Panel, Paginator, DataTable, Textarea, Checkbox, Column, Button, MultiSelect,
			Tooltip, Dialog, ProgressSpinner, InputNumber, InputText, InputSwitch, Dropdown, ConfirmDialog, SelectButton, CustomDropdown
		},
		directives: { tooltip: Tooltip },
		watch: {
			'filters.dt_ocupacao': function(val) {
				this.filters.dt_ocupacao_f = moment(val).format("DD/MM/YYYY")
			},
			'valueTurno': function(val) {
				if(val == 0) this.listaDinamica = this.columns.manha.concat(this.columns.tarde)
				else if(val == 1) this.listaDinamica = this.columns.manha
				else this.listaDinamica  = this.columns.tarde
			},
			selecionados() {
				this.options.estabsUnificar = this.options.estabelecimentos.filter(
					estab => this.selecionados.findIndex(
						procEstab => procEstab.cd_estabelecimento === estab.value
				) !== -1);
				this.estabsUnificar = [];
			},
			estabsUnificar() {
				if (this.estabsUnificar.findIndex(estab => estab.value === this.mainEstab) === -1) {
					this.mainEstab = this.estabsUnificar.length ? this.estabsUnificar[0].value : null;
				}
			}
		},
		data () {
			return {
				submitted: false,
				list: [],
				columns: {
					manha: [
						{ value: "07:00" },
						{ value: "07:30" },
						{ value: "08:00" },
						{ value: "08:30" },
						{ value: "09:00" },
						{ value: "09:30" },
						{ value: "10:00" },
						{ value: "10:30" },
						{ value: "11:00" },
						{ value: "11:30" }
					],
					tarde: [
						{ value: "12:00" },
						{ value: "12:30" },
						{ value: "13:00" },
						{ value: "13:30" },
						{ value: "14:00" },
						{ value: "14:30" },
						{ value: "15:00" },
						{ value: "15:30" },
						{ value: "16:00" },
						{ value: "16:30" },
						{ value: "17:00" },
						{ value: "17:30" },
						{ value: "18:00" },
						{ value: "18:30" },
					],
					dias_semana : [
						{ value: "Domingo" },
						{ value: "Segunda" },
						{ value: "Terça" },
						{ value: "Quarta" },
						{ value: "Quinta" },
						{ value: "Sexta" },
						{ value: "Sábado" },
					]
				},
				listaDinamica:[],
				filters: {
					dt_ocupacao: moment()._d,
					dt_ocupacao_f: moment(this.dt_ocupacao).format("DD/MM/YYYY"),
					mes_ocupacao: null,
					cd_salas: null,
					cd_setores: null,
					cd_estabelecimentos: null,
					cd_especialista: null,
				},
				optionTurno: [
					{ text: "Todos" , value: 0},
					{ text: "Manhã" , value: 1},
					{ text: "Tarde" , value: 2},
				],
				valueTurno: 0,
				options: {
					estabelecimentos: [],
					estabsUnificar: [],
					cd_especialistas: [],
					cd_salas: [],
					cd_setores: [],
					ie_status: [
						{text: 'TODOS', value: '' },
						{text: 'Ativados', value: true },
						{text: 'Desativados', value: false }
					],
					tipo_data: [
						{text: 'Dia', value: 'dia'},
						{text: 'Mês', value: 'mes'}
					],
					tipo_horarios: [
						{text: 'Ocupados', value: 0},
						{text: 'Vagos', value: 1},
						{text: 'Ambos', value: 2}
					],
					tipo_horario_ocupado: [
						{text: 'Ocupados', value: 0}
					],
					dias_vazios:[
						{text: 'Não', value: false},
						{text: 'Sim', value: true}
					]
				},
				paginator: {
					page: 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				order: {
					field: '',
					default: '',
					sortOrder: 1,
				},
				dialogConfirmacao: false,
				dialogObsImprimir: false,
				waitingEstabelecimento: false,
				waitingEspecialistas: false,
				waitingSalas: false,
				waitingSetores: false,
				pdfObservacoes: '',
				waiting: false,
				tipo_data:'dia',
				tipo_horarios:0,
				dias_vazios: false
			}
		},
		validations () {
			let v = {
				filters: {
					cd_estabelecimentos: { required: requiredIf(function () { return this.tipo_data == 'mes' }) },
					cd_salas: { required: false },
					mes_ocupacao: { required: requiredIf(function () { return this.tipo_data == 'mes' }) },
				}
			}
			return v
		},
		methods: {
			formatarData(data, tipo) {
				let meses = {
					1: 'Janeiro',
					2: 'Fevereiro',
					3: 'Março',
					4: 'Abril',
					5: 'Maio',
					6: 'Junho',
					7: 'Julho',
					8: 'Agosto',
					9: 'Setembro',
					10: 'Outubro',
					11: 'Novembro',
					12: 'Dezembro',
				};
			
				if (tipo == 0) {
					let ano = data.getFullYear();
					let dataFormatada = `${meses[data.getMonth() + 1]}/${ano}`; // Usando o mês diretamente do objeto meses
					return dataFormatada;
				} else if (tipo == 1) {
					let partesData = data.split('-');
					let mes = partesData[1];
					let dia = partesData[2];
					let dataFormatada = `${dia}/${mes}`;
					return dataFormatada;
				}
			},
			visualizar(id) {
				const url = this.$router.resolve({ path: `/agenda/visualizar/${id}/` }).href;
				window.open(url, '_blank');
			},
			isPrime(n) {
				if (n===1){
					return false;
				}else if(n === 2){
					return true;
				}else{
					for(let x = 2; x < n; x++){
						if(n % x === 0){
							return false;
						}
					}
					return true;
				}
			},
			generateColor(int_value) {
				if (int_value) {
					if (int_value % 8 === 0 && this.isPrime(int_value)) {
						int_value += 7;
					}
				
					let red = (int_value * 17 + 43) % 255 - 60;
					let green = (int_value * 31 + 73) % 255 - 60;
					let blue = (int_value * 13 + 113) % 255 - 60;
				
					// Estes 'ifs' garantem que não será gerado uma cor cinza
					if (Math.abs(red - green) < 30) {
						green = (green + 70) % 255;
					}
					if (Math.abs(green - blue) < 30) {
						blue = (blue + 140) % 255;
					}
					if (Math.abs(red - blue) < 30) {
						red = (red + 210) % 255;
					}
				
					return `rgb(${red}, ${green}, ${blue})`;
				} else {
					return `rgb(150, 150, 150)`;
				}
			},
			random(max) {
				return Math.floor(Math.random() * max);
			},
			getEspecialistas() {
				this.waitingEspecialistas = true
				this.options.cd_especialistas = []
				this.filters.cd_especialista = null
				
				if(this.filters.cd_estabelecimentos)
					Estabelecimento.corpoClinico({ cd_estabelecimento: this.filters.cd_estabelecimentos, possui_agendas: true }).then(response => {
						this.waitingEspecialistas = false
						if(([200, 201]).includes(response.status)){
							this.options.cd_especialistas = response.data.map(item => ({
								text: item.nm_especialista,
								value: item.id
							}))
						this.options.cd_especialistas.unshift({ text: 'TODOS', value: null })
						}
					})
				else { this.waitingEspecialistas = false }
			},
			getEstabelecimentos(){
				this.waitingEstabelecimento = true
				Estabelecimento.findAllClean({order: 'nm_fantasia', ie_unidades_assistencias: 'true'}, {usarCidade: true}).then(response => {
					this.waitingEstabelecimento = false
					if (response.status === 200) {
						response.data.forEach(e => {
							this.options.estabelecimentos.push({ text: e.nm_fantasia, value: e.id })
						})
						this.options.estabelecimentos.unshift({ text: 'TODOS', value: null })
					}
				})
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			clearFilters() {
				this.filters.dt_ocupacao = moment()._d
				this.filters.cd_estabelecimentos = null
				this.filters.cd_salas = null
				this.filters.cd_especialista = null
				this.filters.cd_setores = null
				this.valueTurno = 0
			},
			onSelectItem() {
				this.onChangeQuantidade(this.selecionados[this.selecionados.length-1])
			},
			onChangeQuantidade(item, qtd) {
				item.nr_quantidade = (qtd && qtd > 0) ? qtd : 1
				item.nr_valor_total = (item.nr_valor_tarifa + item.nr_valor_negociado) * item.nr_quantidade
				item.nr_valor_total_f = this.formatPrice(item.nr_valor_total)
			},
			getSetores (){
				this.waitingSetores = true
					MapaOcupacao.findSetores({ie_status: true, cd_estabelecimento_list: this.filters.cd_estabelecimentos}).then(response => {
						this.waitingSetores = false
						if(([200, 201, 204]).includes(response.status)){
							this.options.cd_setores = response.data.map(item => ({
								text: item.nm_setor,
								value: item.id
							}))
						this.options.cd_setores.unshift({ text: 'TODOS', value: null })
						}
				})
			},
			getSalas (){
				this.waitingSalas = true
					MapaOcupacao.findSalas({
						ie_tipo:'ASS',
						ie_status: true,
						cd_estabelecimento_list: this.filters.cd_estabelecimentos,
						cd_setor: this.filters.cd_setores
					}).then(response => {
						this.waitingSalas = false
						if(([200, 201, 204]).includes(response.status)){
							this.options.cd_salas = response.data.map(item => ({
								text: item.nm_sala,
								value: item.id
							}))
						this.options.cd_salas.unshift({ text: 'TODAS', value: null })
					}
				})
			},
			getList(params) {
				if (this.cancelToken) this.cancelToken.cancel();
				this.cancelToken = axios.CancelToken.source();
				let params_edit = Object.assign({}, params);
				params_edit.dt_ocupacao = moment(params_edit.dt_ocupacao).format("YYYY-MM-DD");
				this.waiting = true;
				this.list = [];
				MapaOcupacao.findAll(params_edit).then(response => {
					if ([200, 201].includes(response.status)) {
						this.waiting = false;
						response.data.forEach(item => {
							let auxitem = {
								sala: item.nm_sala,
								unidade: item.nm_unidade,
								setor: item.nm_setor,
								id: item.id
							};
							this.listaDinamica.forEach(i => {
								const aux = item.ocupacoes.find(j => j.hora == i.value);
								if (aux) {
									auxitem[i.value] = {
										nm_especialista: aux.nm_especialista,
										cor: this.generateColor(aux.id_especialista),
										id_agenda: aux.id_agenda,
										id_especialista: aux.id_especialista
									};
								} else {
									auxitem[i.value] = null;
								}
							});
							this.list.push(auxitem);
						});
					}
				});
			},
			getListMes(params) {
				if (this.cancelToken) this.cancelToken.cancel();
				this.cancelToken = axios.CancelToken.source();
				let params_edit = Object.assign({}, params);
				params_edit.mes_ocupacao = moment(params_edit.mes_ocupacao).format("YYYY-MM");
				params_edit.dt_ocupacao = null;
				this.waiting = true;
				this.list = [];
				MapaOcupacao.findAll(params_edit).then(response => {
					if ([200, 201].includes(response.status)) {
						this.waiting = false;
						this.list = response.data;
					
						for (let i = 0; i < this.list.length; i++) {
							let dates = Object.keys(this.list[i]); // Obter as chaves de cada objeto
							for (let j = 0; j < dates.length; j++) {
								let mapa = this.list[i][dates[j]].mapa; // Obter o array "mapa" dentro do objeto
								for (let k = 0; k < mapa.length; k++) {
									let ocupacoes = mapa[k].ocupacoes; // Obter o array "ocupacoes" dentro de "mapa"
									for (let l = 0; l < ocupacoes.length; l++) {
										// Adicionar o campo "cor" usando a função generateColor
										ocupacoes[l].cor = this.generateColor(ocupacoes[l].id_especialista);
									}
								}
							}
						}
					}
				});
			},
			applyFilters (page) {
				this.paginator.page = page || 1
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page, tipo_data: this.tipo_data, dias_vazios: this.dias_vazios }
				params.order = `${ this.order.sortOrder === -1 ? '-' : '' }${ this.order.field || this.order.default}`
				Object.keys(this.filters).forEach((key) => {
					if ( this.filters[key] != null && this.filters[key] !== '' ) params[key] = this.filters[key]
				})

				this.submitted = true
				this.$v.$touch()
				if (this.$v.$invalid) return 0

				if(this.tipo_data == 'dia')
					this.getList(params)
				else if(this.tipo_data == 'mes')
					this.getListMes(params)
			},
			onPage (ev) {
				this.applyFilters(ev.page + 1)
			},
			onSort (ev) {
				this.order.field = ev.sortField
				this.order.sortOrder = ev.sortOrder
				this.applyFilters()
			},
			gerarPdf () {
				let painel = document.getElementsByClassName('panel-print')[0];
				
				let element = `
					<html>
						<body>${painel.innerHTML}</body>
					</html>
				`
						
				let opt = {
					margin:       [25, 10, 17, 10],
					filename:     'mapa-ocupacional.pdf',
					image:        { type: 'png', quality: 1 },
					html2canvas:  { dpi: 300, scale: 2, scrollX: 0, scrollY: 0, ignoreElements: (el) => el.nodeName === 'SCRIPT' },
					jsPDF:        { unit: 'mm', format: 'A3', orientation: 'landscape' },
					pagebreak:    { avoid: '.p-datatable-row' },
				};

				html2pdf().set(opt).from(element).toPdf().get('pdf').then((pdf) => {
					let fontSize = 8;
					let pageWidth = pdf.internal.pageSize.getWidth();
					let pageHeight = pdf.internal.pageSize.getHeight();
					let totalPages = pdf.internal.getNumberOfPages();
					let usuario = JSON.parse(localStorage.getItem('usuario'))
					let userNameText = `Usuário: ${ usuario.username }`;
					let userNameTextWidth = pdf.getStringUnitWidth(userNameText) * fontSize / pdf.internal.scaleFactor;
					let printDateTxt = `Impresso em: ${moment(new Date()).format('DD/MM/YYYY HH:mm')}`;
				
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(fontSize);
						pdf.addImage(logoMedclubBase64, 'png', pageWidth / 2 - 20, 10, 40, 8);
						pdf.line(15, pageHeight - 13, pageWidth - 15 , pageHeight - 13);
						pdf.text('Página ' + i + ' de ' + totalPages, pageWidth - 30, pageHeight - 10);
						pdf.text(userNameText, (pageWidth - userNameTextWidth) / 2, pageHeight - 10);
						pdf.text(printDateTxt, 15, pageHeight - 10);
					
						if (i > 1) {
							pdf.setDrawColor(153, 153, 153);
							pdf.line(10.2, 25, pageWidth - 10.2 , 25);
						}
					}
				}).output('bloburl').then((result) => {
					window.open(result);
				}).finally(() => this.$emit('finished'));
			},
		}
	}
</script>
